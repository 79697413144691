@use 'variables' as *

//region Section
@mixin section()
	background: white
	padding: 20px 30px
	box-shadow: 2px 2px 8px rgba(black, .2)

@mixin shop-shadow($inset: false)
	@if $inset
		box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.2)
	@else
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2)
//endregion
//region @MEDIA
/// Function to get a value in a map from a given key. If the key isn't contained in the map, returns a default value (map item at index: $index_default)
///
/// @param $map: Map containing items
/// @param $key: Key of the item
/// @param $index_default: index of the default item if the key isn't found
///
/// @return Map value corresponding to the given key or the default item value defined by the default index
@function zi-map-get($map, $key, $index_default: 1)
	// If the key exists in the map : return the value associated
	// Else, return the map's item at index: $index_default
	@if (map-has-key($map, $key))
		@return map-get($map, $key)
	
	@else
		@return map-get($map, nth(map-keys($map), $index_default))

// Sizes :
// Mobile       -   767px   -   mobileL
// Tablet       -   1024px   -   tablet
// Monitor      -   1920px  -   monitor
@mixin zi-media($limit: '<=', $screen: tablet)
	//region Maps
	// Map containing sizes of each screen
	$pixel-sizes: (mobile: 767px, tablet: 1024px, monitor: 1920px)
	// Map containing limits data : (width value modification / function for @media)
	$limits-map: ('<': ('value': -1px, 'function': max-width), '<=': ('value': 0px, 'function': max-width), '>=': ('value': 0px, 'function': min-width), '>': ('value': 1px, 'function': min-width))
	//endregion
	//region Current values
	// Size of the current screen (default: tablet)
	$pixel-size: zi-map-get($pixel-sizes, $screen)
	// Limit values for the defined limit (default: '<=')
	$limit_values: zi-map-get($limits-map, $limit, 2)
	//endregion
	// Update pixel-size from limit value (default: first element of the map)
	$pixel-size: $pixel-size + zi-map-get($limit_values, 'value')
	
	// Create media with the limit function and the pixel-size. (limit function: by default its the second element of the map)
	@media (#{zi-map-get($limit_values, 'function', 2)}: ($pixel-size))
		@content
//endregion

@mixin z-padding-inline
	padding-inline: var(--padding-inline-value)

@mixin z-padding-block
	padding-block: var(--padding-block-value)

@mixin z-margin-inline
	margin-inline: var(--padding-inline-value)

@mixin z-padding
	@include z-padding-inline
	@include z-padding-block

@mixin z-padding-footer
	@include z-padding-inline
	@include z-padding-block
	padding-top: calc(8rem + 4rem)

@mixin correctTransformGlitch()
	@include zi-media('>', tablet)
		will-change: transform

// Default grid style
@mixin z-grid($columns: 13)
	display: grid
	grid-template-columns: repeat($columns, 1fr)
	gap: 2rem

// Default button style
@mixin z-button
	// Size
	width: fit-content
	text-decoration: none
	
	// Background style
	background-color: $color-orange-500
	padding: 1rem 2rem
	border-radius: 999px
	
	// Font
	@include z-font-body
	text-transform: uppercase
	color: $color-bleu-900
	
	// Cursor
	cursor: pointer
	
	// Border
	border: none

@mixin z-animated-button
	@include z-button
	
	overflow: hidden
	position: relative
	
	isolation: isolate
	
	//region Before
	&:before
		content: ''
		position: absolute
		
		width: 120%
		height: auto
		aspect-ratio: 1
		
		top: 100%
		left: 50%
		
		background-color: $color-bleu-900
		border-radius: 999px
		
		z-index: -1
		
		pointer-events: none
		
		transform: translate(-50%, 0)
		
		transition-property: top, transform
		transition-duration: .5s
		transition-timing-function: ease-out
	
	&:hover
		&:before
			top: 50%
			transform: translate(-50%, -50%)
	
	//endregion
	
	.container
		overflow: hidden
		position: relative
		
		.text, .hovered_text
			will-change: top
		
		.text
			position: relative
			color: $color-bleu-900
			top: 0
			
			transition: top .5s ease
		
		.hovered_text
			position: absolute
			color: white
			
			top: -50%
			left: 50%
			transform: translate(-50%, -50%)
			
			width: fit-content
			white-space: nowrap
			
			transition: top .5s ease
	
	&:hover
		.container
			.text
				top: -2em
			
			.hovered_text
				top: 50%

@mixin z-grid-background($lines_color: white, $square_color: transparent, $lines_size: 1px, $square_size: 80px)
	position: relative
	isolation: isolate
	
	&:before
		content: ''
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		
		width: 100%
		height: 100%
		
		background: repeating-linear-gradient($square_color, $square_color calc($square_size / 2), $lines_color calc($square_size / 2), $lines_color calc($square_size / 2 + $lines_size), $square_color calc($square_size / 2 + $lines_size), $square_color calc($square_size - $lines_size)), repeating-linear-gradient(.25turn, $square_color, $square_color calc($square_size / 2), $lines_color calc($square_size / 2), $lines_color calc($square_size / 2 + $lines_size), $square_color calc($square_size / 2 + $lines_size), $square_color calc($square_size - $lines_size))
		
		z-index: -1

@mixin z-title-margin-second-line
	line-height: 1.2
	--margin-left-value: 4rem
	margin-left: calc(2 * var(--margin-left-value))
	
	&:before
		content: ''
		height: 1.2em
		margin-left: calc(-1 * var(--margin-left-value))
	
	@include zi-media
		--margin-left-value: 2rem
	
	@include zi-media('<=', mobile)
		--margin-left-value: 1rem
