@use 'abstract' as *

// TODO: Install Inter font
//region Remove default styling
// 1. Use a more-intuitive box-sizing model.
*, *::before, *::after
	box-sizing: border-box

// 2. Remove default margin
*
	margin: 0

// 3. Allow percentage-based heights in the application
html, body
//height: 100%

// Typographic tweaks!
// 4. Add accessible line-height
// 5. Improve text rendering
body
	line-height: 1.5
	-webkit-font-smoothing: antialiased

// 6. Improve media defaults
img, picture, video, canvas, svg
	display: block
	max-width: 100%

// 7. Remove built-in form typography styles
input, button, textarea, select
	font: inherit

// 8. Avoid text overflows
p, h1, h2, h3, h4, h5, h6
	overflow-wrap: break-word

p
	line-height: 1.3

// 9. Create a root stacking context
#root, #__next
	isolation: isolate

//endregion
//region Fonts
// Inter
@font-face
	font-family: Inter
	src: url('./fonts/Inter-VariableFont_wght.ttf') format('truetype-variations')
	font-weight: 100 900
	font-stretch: 50% 200%
	font-display: swap

//region Old Standard TT
// Regular
@font-face
	font-family: 'Old Standard TT'
	src: url('./fonts/OldStandardTT-Regular.ttf') format('truetype-variations')
	font-weight: 400
	font-display: swap
// Bold
@font-face
	font-family: 'Old Standard TT'
	src: url('./fonts/OldStandardTT-Bold.ttf') format('truetype-variations')
	font-weight: 700
	font-display: swap
// Italic
//@font-face
//	font-family: 'Old Standard TT'
//	src: url('./fonts/OldStandardTT-Italic.ttf') format('truetype-variations')
//	font-weight: 400
//	font-style: italic
//	font-display: swap
//endregion

html
	font-family: Inter, serif
	font-weight: normal
	font-style: normal
	//font-size: 24px
	font-size: 16px
	line-height: 1.3em
	color: $color-bleu-500
	
	body
		font-size: 1.5rem

a
	color: inherit
//endregion

html
	--border-radius-value: 5rem
	--border-radius-small-value: var(--border-radius-value)
	--padding-inline-value: 2.5rem
	--padding-block-value: 8rem
	
	@include zi-media
		--border-radius-small-value: 3rem
		
		--padding-inline-value: 2rem
		--padding-block-value: 4rem
	
	@include zi-media('<=', mobile)
		--border-radius-small-value: 2.5rem
		
		--padding-inline-value: 1rem
		--padding-block-value: 2rem

.transition-container
	position: relative
	background-color: $color-orange-500
	min-height: 100vh
	width: 100%
	overflow: hidden
	
	> *
		box-shadow: 0 0 0 1.5rem $color-orange-50
		
		&:before
			content: ''
			position: absolute
			inset: -1.5rem
			
			box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.2)
	
	> * > *
		background-color: white
		
		> *
			position: relative
			height: 100%

main
	isolation: isolate
